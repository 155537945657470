import React from 'react'
import dynamic from 'next/dynamic'
import {NextPageContext} from 'next'
import {fetchSSRData} from 'src/page-utils'
import {CLIENT_ENV} from 'src/constants/clientEnv'

const isWidgetizedHomePage = CLIENT_ENV.NEXT_PUBLIC_ENABLE_NEW_HOME || false

const AuditedWidgetizedScreen = dynamic(
  import(
    /* webpackChunkName: "deprecated-widget-home-page" */ '../src/Screens/AuditedWidgetizedScreen/WidgetizedScreen'
  )
)
const NewHomePage = dynamic(
  import(
    /* webpackChunkName: "deprecated-widget-home-page" */ '../src/components/Home/NewHomePage'
  )
)
const OldHomePage = dynamic(
  import(
    /* webpackChunkName: "original-home-page" */ '../src/components/Home/OldHomePage'
  )
)

function Homepage(props: any) {
  const {pageData} = props || {}
  const {data} = pageData || {}
  const {newScheme = false} = data || {}

  if (isWidgetizedHomePage) return <AuditedWidgetizedScreen data={data} />
  else if (newScheme) {
    return <NewHomePage data={data} />
  }

  return <OldHomePage data={data} />
}

export async function getServerSideProps(context: NextPageContext) {
  const url = isWidgetizedHomePage ? '/page/widgets/home' : 'page/home'
  return fetchSSRData(context, [
    {
      key: 'data',
      url: url,
      isPrimary: true,
    },
  ])
}

export default Homepage
